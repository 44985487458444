<template>
<div class="my-border ma-0 pa-0">
    <div class="elevation-0 pa-0 ma-0 white" style="min-height:calc(100vh - 120px)">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" class="pa-3 ma-0">
          <v-select
            v-model="filters.range"
            :items="dates"
            class=""
            :label="$t('select fiscal year')"
            @change="searchfilter"
            outlined dense
            hide-details
            clearable>
          </v-select>
        </v-col>
        <v-col cols="12" class="pa-3 ma-0">
          <budget-table class="mb-7"></budget-table>
          <expense-table></expense-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import BudgetTable from "../components/finances/BudgetTable";
import ExpenseTable from "../components/finances/ExpensesTable";
import moment from 'moment'
export default {
  components: {
    BudgetTable,
    ExpenseTable
  },
  data() {
    return {
      error: "",
      dates:[],
      errored: false,
      filters: {
        range:[],
      },
    };
  },

  async mounted() {
    let thisyear=moment().year()
    let thismonth=moment().month()
    if(thismonth+1>3){
      let startd=thisyear+'-04-01';
      let endd=thisyear+1+'-03-31';
      this.filters.range=[startd,endd];
      this.filterBudget(this.filters)
    }
    else{
      let startd=thisyear-1+'-04-01';
      let endd=thisyear+'-03-31';
      this.filters.range=[startd,endd];
      this.filterBudget(this.filters)
    }
    for(let i=thismonth>2?thisyear+1:thisyear;i>2000;i--){
      let text= i-1+'-'+i;
      let startd=i-1+'-04-01';
      let endd=i+'-03-31';
      let val=[startd,endd];
      this.dates.push({value:val,text:text})
    }
    Object.assign(this.filters, this.storeFilters);
  },

  computed: {
    ...mapState('finance', {
      storeFilters:"filter"
    }),
  },
  watch: {
      storeFilters: {
        handler(){
            Object.assign(this.filters, this.storeFilters);  
        }, deep: true 
      },
  },
  methods: {
    ...mapActions('finance',['filterBudget']),
    searchfilter(){
      if(this.filters.range==undefined){
        this.filters={
          range:[],
        }
      }
      this.filterBudget(this.filters);
    },
  }
};
</script>