<template>
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
   <div class=" my-border">
     <v-card-title class="py-0 my-0 customlightgray">
       <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{ $t("expenses") }}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                        class="custom-color-accent pa-1">{{filteredExpense.length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search.searchWord"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                   <template v-slot:prepend-inner>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on='on' v-bind="attrs" @click="dialog_adv=true" class="text--secondary">mdi-cog</v-icon>
                        </template>
                        <span>{{$t("advanced")}}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed @click="addItem" v-if="$checkPermission('AdminMenu.Read')">{{$t("addexpense")}}</v-btn>
            </v-col>
            
        </v-row>
      </v-card-title>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t(formTitle)}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-form  v-on:submit.prevent ref="form"
              v-model="valid"
              lazy-validation >
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                          :items="getLookup('FinCat')"
                          :label="$t('category')"
                          :placeholder="$t('category')"
                          :rules="[rules.required]"
                          v-model="selectedItem.financialCategory"
                          outlined
                          dense
                          clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                     <v-text-field
                      type="number"
                      v-model.number="selectedItem.amount"
                      :rules="[rules.required]"
                      :label="$t('amount')"
                      :placeholder="$t('amount')"
                      dense outlined hide-details
                  ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                   <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto">
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              prepend-inner-icon="mdi-calendar"
                              outlined dense
                              :rules="[rules.required]" v-model="selectedItem.effective_date"
                              v-on="on" :label="$t('effectivedate')" :placeholder="$t('effectivedate')"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            dark
                            :rules="[rules.required]" v-model="selectedItem.effective_date"
                          ></v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                   <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto">
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              prepend-inner-icon="mdi-calendar"
                              outlined dense
                              :rules="[rules.required]" v-model="selectedItem.expireDate"
                              v-on="on" :label="$t('expirydate')" :placeholder="$t('expirydate')"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            dark
                            :rules="[rules.required]" v-model="selectedItem.expireDate"
                          ></v-date-picker>
                      </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                        grow
                        outlined 
                        v-model="selectedItem.description"
                        :label="$t('description')"
                        :placeholder="$t('description')"
                      ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="close = dialog = false">{{$t('cancel')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="save">{{$t('save')}}</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_adv"  max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="headline">{{$t("advanced")}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                      v-model="filters.range"
                      :items="dates"
                      :label="$t('select fiscal year')"
                      :placeholder="$t('select fiscal year')"
                      outlined dense
                      hide-details
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="close = dialog_adv = false">{{$t('cancel')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="searchfilter">{{$t('search')}}</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title>{{$t('delete')}}</v-card-title>
          <v-card-text>{{$t('Are you sure you want to delete this item?')}}</v-card-text>
          <v-card-actions>
            <v-btn class="custom-color-accent-text" text @click="dialogDelete = false">{{$t('Close')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="deleteBud()">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
       <v-divider  v-show="filterList.length>0"></v-divider>       
        <v-row class="pa-0 ma-0 white" v-show="filterList.length>0">
                <v-col class="pa-0 ma-0" cols="12">
                  <v-scroll-x-transition group hide-on-leave>
                  <v-tooltip v-for="(f, i) in filterList" :key="i" right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip  v-on='on' v-bind="attrs"
                        class="ma-1"
                        color="custom-color-accent"
                        close
                        small
                        close-icon="mdi-delete"
                        @click:close="removeFilter(f)" >
                          {{f}}
                        </v-chip>
                      </template>
                      <span>{{$t("removefilter")}}</span>
                    </v-tooltip>
                  </v-scroll-x-transition>
                </v-col>
        </v-row> 
         
      <v-divider></v-divider>
      <v-data-table :mobile-breakpoint="1081" 
          :headers="headers"
          :items="filteredExpense"
          sort-by="id"
          class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
          :search="search.searchWord"
          :loading="loading" 
          :loading-text="$t('Loading')"
          :footer-props="{ 'items-per-page-text': $t('rowsperpage') }"
        >
        <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
        <template class="pa-0 ma-0" v-slot:[`item.amount`]="{ item }">
         $ {{item.amount}}
        </template>
        <template class="pa-0 ma-0" v-slot:[`item.financialCategory`]="{ item }">
         {{getCategory().filter(i=>i.value==item.financialCategory)[0].text}}
        </template>
        <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
          <v-row class="ma-0 pa-0 text-right">
            <v-col cols="12" class="ma-0 pa-0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("edit")}}</span>
              </v-tooltip>
               <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("delete")}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import moment from "moment";
export default {
  name: "Holidays",
  data(){
    return {
      valid: true,
      rules: {
          required: value => !!value || this.$t('required'),
      },
      dates:[],
      year:moment().year(),
      selectedYear:null,
      editFlag: false,
      deletedItem: {},
      dialog_adv: false,
      selectedItem: {},
      editedIndex: 0,
      headers: [
        {
          text: this.$t('ID'),
          value: "id"
        },
        {
          text: this.$t('category'),
          value: "financialCategory",
          width: '250px'
        },
        {
          text: this.$t('amount'),
          value: "amount"
        },
        {
          text: this.$t('effectivedate'),
          value: "effective_date"
        },
        {
          text: this.$t('expirydate'),
          value: "expireDate"
        },
        { text: "", value: "action", sortable: false }
      ],
      dialog: false,
      error: "",
      dialogDelete: false,
      lookupnames: [],
      filterList: [],
      search: {
        searchword:'',
      },
      filters: {
        range:[],
      },
    }
  },
  async mounted(){
    await this.getExpense();
    await this.filterExpense(this.filters);
    let thisyear=moment().year()
    let thismonth=moment().month()
    for(let i=thismonth>2?thisyear+1:thisyear;i>2000;i--){
      let text= i-1+'-'+i;
      let startd=i-1+'-04-01';
      let endd=i+'-03-31';
      let val=[startd,endd];
      this.dates.push({value:val,text:text})
    }
    Object.assign(this.filters, this.storeFilters);
    this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
    this.filterList = this.filterList.filter(el=> el != "");
  },
  computed: {
    ...mapGetters('finance', {
      filteredExpense: 'filteredExpense',
    }),
    ...mapState('finance', {
      loading:'loadingexpense',storeFilters:"filter"
    }),
    ...mapState("configuration", {
      configurations: "configurations"
    }),
    formTitle() {
      return this.editedIndex === 0 ? "newexpense" : "editexpense";
    }
  },
  watch: {
    storeFilters: {
      handler(){
          Object.assign(this.filters, this.storeFilters);  
          this.filterList=Object.values(this.filters).flat().map(a=>a.text!== undefined ?a.text:a); 
                this.filterList = this.filterList.filter(el=> el != "");
      }, deep: true 
    },
  },
  methods:{
    ...mapActions('finance',['getExpense','addExpense', 'updateExpense', 'deleteExpense','filterExpense']),
    removeFilter(item){
        Object.keys(this.filters).forEach((key)=>{
        if( !Array.isArray(this.filters[key]) && (this.filters[key].text==item))
          this.filters[key]={text:"",value:""};        
        else if( !Array.isArray(this.filters[key]) && (this.filters[key]==item))
          this.filters[key]="";           

        else if(Array.isArray(this.filters[key]) && this.filters[key].map(a=>a.text).includes(item))
        this.filters[key]= this.filters[key].filter(a=>a.text!=item);

        else if(Array.isArray(this.filters[key]) && this.filters[key].includes(item))
        this.filters[key]= this.filters[key].filter(a=>a!=item);
        });
        this.filterExpense(this.filters);
      }, 
    searchfilter(){
      this.filterExpense(this.filters);
      this.dialog_adv = false;
    },
    clearFilters(){
      this.filters.year=null;
      this.filterExpense(this.filters);
    },
    save(){
      if(this.editFlag){
         if(this.$refs.form.validate()){
          this.updateExpense(this.selectedItem).then(()=>{
            this.filterExpense(this.filters); 
            let color="dark";
            let alert="itemUpdatedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
            this.editFlag = false;
          })
        }
        else
          return
      }else{
        if(this.$refs.form.validate()){
          this.selectedItem.budgetOrExpense='Expense';
          this.addExpense(this.selectedItem).then(()=>{
            this.filterExpense(this.filters);
            let color="success";
            let alert="itemAddedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
          })
          
        }
        else
          return
      }
      this.dialog = false;
    },
    getLookup( column ){
      return this.configurations.filter( filter => filter.LookUpName === column ).map( item => {
        let arr = [];
        arr['text'] = localStorage.getItem('language') === 'en' ? item.LookUpStringE : item.LookUpStringF;
        arr['value'] = item.LookUpKey;
        return  arr;
      })
    },
    getCategory(){
      return this.getLookup('FinCat')?.map(i=>{return {value:i.value,text:i.text}})
    },
    addItem(){
        this.dialog = true;
        this.selectedItem = {};
        this.editedIndex = 0;
        this.editFlag = false;
    },
    editItem(item){
        this.dialog = true;
        Object.assign(this.selectedItem,item);
        this.editedIndex = -1;
        this.editFlag = true;
    },
    deleteItem(item){
        Object.assign(this.deletedItem,item);
        this.dialogDelete = true;
    },
    async deleteBud(){
      this.dialogDelete = false;
      this.deleteExpense(this.deletedItem).then(()=>{
          this.filterExpense(); 
          let color="dark";
          let alert="itemRemovedAlert";
          this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
      })
    },
  }

}
</script>

<style scoped>

</style>